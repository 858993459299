var render = function () {
  var _vm$currency, _vm$amount;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.type === 'currency' ? [_vm._v(" " + _vm._s((_vm$currency = _vm.currency) !== null && _vm$currency !== void 0 ? _vm$currency : _vm.defaultCurrency) + " ")] : _vm._e(), _vm.type === 'amount' ? [_c('b', [_vm._v(" " + _vm._s((_vm$amount = _vm.amount) === null || _vm$amount === void 0 ? void 0 : _vm$amount.toLocaleString()) + " ")])] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }