<template>
  <div>
    <div>
      <!-- select project type -->
      <div class="demo-inline-spacing mb-2">
        <b-row>
          <b-col class="col-6">
            <b-form-radio
              v-model="appliedTo"
              name="applied_to"
              value="users"
              class="ml-5 mr-5"
              @change="resetDepartmentSelection"
            >Users</b-form-radio>
          </b-col>

          <b-col class="col-6">
            <b-form-radio
              v-model="appliedTo"
              name="applied_to"
              value="departments"
              class="ml-3"
              @change="resetUserSelection"
            >
              Departments
            </b-form-radio>
          </b-col>
        </b-row>
      </div>
      <template v-if="appliedTo == 'departments'">
        <b-form-checkbox
          v-model="isAllDeptSelected"
          class="mb-1"
          @change="sendDataToParentPolicy"
        >
          All Departments
        </b-form-checkbox>

        <template v-if="!isAllDeptSelected">
          <b-form-group
            label="Department "
            label-for="department_id"
            class="required-label"
          >
            <ValidationProvider
              name="department "
              v-slot="{ errors }"
              vid="department_id"
              rules="required"
            >
              <v-select
                id="department_id"
                placeholder="Choose department"
                v-model="departmentIds"
                :options="departmentIdOptions"
                :reduce="(item) => item.id"
                label="name"
                class="custom-font"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                @input="sendDataToParentPolicy"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group label="Except Users " label-for="except_user_id">
            <ValidationProvider
              name="user "
              v-slot="{ errors }"
              vid="except_user_id"
            >
              <v-select
                id="except_user_id"
                placeholder="Choose User"
                v-model="exceptUserIds"
                :options="exceptUserIdOptions"
                :reduce="(item) => item.id"
                label="name"
                class="custom-font"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                @input="sendDataToParentPolicy"
              >
              <template #option="data">
                <UserSelect :user="data" />
              </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </template>
      </template>

      <template v-else-if="appliedTo == 'users'">
        <b-form-checkbox
          v-model="isAllUserSelected"
          class="mb-1"
          @change="sendDataToParentPolicy"
        >
          All Users
        </b-form-checkbox>
        <template v-if="!isAllUserSelected">
          <b-form-group
            label="User "
            label-for="user_id"
            class="required-label"
          >
            <ValidationProvider
              name="user"
              v-slot="{ errors }"
              vid="user_id"
              rules="required"
            >
              <v-select
                id="user_id"
                placeholder="Choose User"
                v-model="userIds"
                :options="userIdOptions"
                :reduce="(item) => item.id"
                @input="sendDataToParentPolicy"
                label="name"
                class="custom-font"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
              >
              <template #option="data">
                <UserSelect :user="data" />
              </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </template>
      </template>
    </div>
  </div>
</template>

  <script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormTextarea,
  BFormDatepicker,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  VBTooltip,
  BFormRadio,
  BFormCheckbox,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import {
  LEAD_MANAGEMENT_ACCESS,
  LEAD_DETAILS_EXPORT,
  LEAD_DETAILS_CREATE,
  LEAD_DETAILS_EDIT,
  LEAD_DETAILS_DELETE,
} from "@/helpers/permissionsConstant";
import UserSelect from '@/layouts/components/UserSelect.vue'
export default {
  name: "UserAssignPolicyForm",

  components: {
    UserSelect,
    BLink,
    BForm,
    BButton,
    BCard,
    BFormRadio,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,

    BModal,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: ["selectedLeadInfo", "leadTitle"],
  data() {
    return {
      LEAD_MANAGEMENT_ACCESS,
      LEAD_DETAILS_EXPORT,
      LEAD_DETAILS_CREATE,
      LEAD_DETAILS_EDIT,
      LEAD_DETAILS_DELETE,
      //Custom Validation
      exceptUserIdOptions: [],
      exceptUserIds: [],
      userIdOptions: [],
      userIds: [],
      departmentIdOptions: [],
      departmentIds: [],
      isLoading: false,
      appliedTo: "users",
      isAllDeptSelected: false,
      isAllUserSelected: false,
    };
  },
  computed: {
    getLeadInfo() {
      return this.selectedLeadInfo;
    },
  },
  async created() {
    try {
      const [users, departments] = await Promise.all([
        this.getUsers(),
        this.getDepartments(),
      ]);

      this.userIdOptions = (users?.data?.data || []).map((item) => {
        return {
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }
      })

      this.exceptUserIdOptions = this.userIdOptions;

      this.departmentIdOptions = (departments?.data?.data || []).map((item) => {
        let name = item?.name;
        return {
          name,
          id: item.id,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    // loadDepartmentWiseUser(){

    // },
    resetDepartmentSelection() {
      this.isAllDeptSelected = false;
      this.departmentIds = [];
      this.exceptUserIds = [];

      // this.$refs.assignUserPolicy.reset();
    },
    resetUserSelection() {
      this.isAllUserSelected = false;
      this.userIds = [];
      // this.$refs.assignUserPolicy.reset();
    },
    async getUsers() {
      return await this.$api.get("api/users/active-all");
    },
    async getDepartments() {
      return await this.$api.get("api/departments/all");
    },
    sendDataToParentPolicy() {
      this.isLoading = true;

      this.exceptUserIdOptions = this.userIdOptions.filter((element) => {
        return this.departmentIds.includes(element?.departmentId);
      });

      const data = {
        appliedTo: this.appliedTo,
        isAllDeptSelected: this.isAllDeptSelected,
        isAllUserSelected: this.isAllUserSelected,
        userIds: this.userIds,
        departmentIds: this.departmentIds,
        exceptUserIds: this.exceptUserIds,
      };

      this.$emit("userAssignFromDataEvent", data);
      this.isLoading = false;
    },
  },
};
</script>

  <style lang="scss">
.custom-font-validation {
  font-size: 11px;
}
.required-label label::after {
  content: " *";
  color: red;
}
</style>
