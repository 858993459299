var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', {
    staticClass: "demo-inline-spacing mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "col-6"
  }, [_c('b-form-radio', {
    staticClass: "ml-5 mr-5",
    attrs: {
      "name": "applied_to",
      "value": "users"
    },
    on: {
      "change": _vm.resetDepartmentSelection
    },
    model: {
      value: _vm.appliedTo,
      callback: function callback($$v) {
        _vm.appliedTo = $$v;
      },
      expression: "appliedTo"
    }
  }, [_vm._v("Users")])], 1), _c('b-col', {
    staticClass: "col-6"
  }, [_c('b-form-radio', {
    staticClass: "ml-3",
    attrs: {
      "name": "applied_to",
      "value": "departments"
    },
    on: {
      "change": _vm.resetUserSelection
    },
    model: {
      value: _vm.appliedTo,
      callback: function callback($$v) {
        _vm.appliedTo = $$v;
      },
      expression: "appliedTo"
    }
  }, [_vm._v(" Departments ")])], 1)], 1)], 1), _vm.appliedTo == 'departments' ? [_c('b-form-checkbox', {
    staticClass: "mb-1",
    on: {
      "change": _vm.sendDataToParentPolicy
    },
    model: {
      value: _vm.isAllDeptSelected,
      callback: function callback($$v) {
        _vm.isAllDeptSelected = $$v;
      },
      expression: "isAllDeptSelected"
    }
  }, [_vm._v(" All Departments ")]), !_vm.isAllDeptSelected ? [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Department ",
      "label-for": "department_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "department ",
      "vid": "department_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "department_id",
            "placeholder": "Choose department",
            "options": _vm.departmentIdOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          on: {
            "input": _vm.sendDataToParentPolicy
          },
          model: {
            value: _vm.departmentIds,
            callback: function callback($$v) {
              _vm.departmentIds = $$v;
            },
            expression: "departmentIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 492245274)
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Except Users ",
      "label-for": "except_user_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "user ",
      "vid": "except_user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "except_user_id",
            "placeholder": "Choose User",
            "options": _vm.exceptUserIdOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          on: {
            "input": _vm.sendDataToParentPolicy
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.exceptUserIds,
            callback: function callback($$v) {
              _vm.exceptUserIds = $$v;
            },
            expression: "exceptUserIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1378126067)
  })], 1)] : _vm._e()] : _vm.appliedTo == 'users' ? [_c('b-form-checkbox', {
    staticClass: "mb-1",
    on: {
      "change": _vm.sendDataToParentPolicy
    },
    model: {
      value: _vm.isAllUserSelected,
      callback: function callback($$v) {
        _vm.isAllUserSelected = $$v;
      },
      expression: "isAllUserSelected"
    }
  }, [_vm._v(" All Users ")]), !_vm.isAllUserSelected ? [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "User ",
      "label-for": "user_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "user",
      "vid": "user_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "user_id",
            "placeholder": "Choose User",
            "options": _vm.userIdOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          on: {
            "input": _vm.sendDataToParentPolicy
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.userIds,
            callback: function callback($$v) {
              _vm.userIds = $$v;
            },
            expression: "userIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4127969491)
  })], 1)] : _vm._e()] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }